import "../../styles/layouts/logistics-services.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { wavePAQs_svg } from "../../assets/svg/home_elements";
import BenefitsAndInfo from "../../components/BenefitsAndInfo/BenefitsAndInfo";
import BrandsLogistics from "../../components/Brands/BrandsLogistics";
import Gallery from "../../components/Gallery/Gallery";
import HeroService from "../../components/Hero/HeroService/HeroService";
import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";
import ServiceMarkupSchema from "../../components/SEO/ServiceMarkupSchema";
import ServicesResume, { ServiceResume } from "../../components/ServicesResumen/ServicesResume";
import SubscribeNewsletter from "../../components/SubscribeNewsletter/SubscribeNewsletter";
import Video from "../../components/Video/Video";
import { Problem } from "../../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../../components/WeTalk/WeTalk";
import { Photo, Slug } from "../../types/shared";

const FoodServicesServicesTemplate = ({ data }: PageProps<Queries.FoodServicesServicesQuery>) => {
  if (!data.sanityTemplateFoodServicesServices) return;
  const photosList = data.sanityTemplateFoodServicesServices.gallery as Photo[];

  const has_video = !!data.sanityTemplateFoodServicesServices.video?.code;
  let videoThumbnail: Photo | null = null;
  if (has_video) {
    const alt = data.sanityTemplateFoodServicesServices.video?.alt
      ? data.sanityTemplateFoodServicesServices.video?.alt
      : "";
    videoThumbnail = {
      image: data.sanityTemplateFoodServicesServices.video?.photo,
      alt,
    };
  }

  const servicesResumenList: ServiceResume[] = [];
  data.allSanityTemplateFoodServicesServices.nodes.forEach((service) => {
    if (!service.hero?.title || !service.seo?.thumbnail) {
      return;
    }
    servicesResumenList.push({
      name: service.hero.title,
      photo: { image: service.seo.thumbnail, alt: `Foto de portada de ${service.hero.title}` } as unknown as Photo,
      slug: { current: `/distribucion-comercial/${service.slug?.current}/` } as Slug,
    });
  });
  servicesResumenList.push({
    name: data.sanityPageOurProducts?.hero?.title as string,
    photo: {
      image: data.sanityPageOurProducts?.seo?.thumbnail,
      alt: `Foto de portada de ${data.sanityPageOurProducts?.hero?.title}`,
    } as unknown as Photo,
    slug: { current: `/distribucion-comercial/${data.sanityPageOurProducts?.slug?.current}/` } as Slug,
  });
  if (data.sanityPageApp?.seo?.thumbnail) {
    const serviceApp: ServiceResume = {
      slug: { current: `/app-usicom/` },
      name: `Usicom`,
      photo: { image: data.sanityPageApp?.seo?.thumbnail, alt: `Foto de portada de Usicom` } as unknown as Photo,
    };
    servicesResumenList.push(serviceApp);
  }
  const problemList = data.sanityTemplateFoodServicesServices.weKnowYourProblems?.PAQsList
    ? (data.sanityTemplateFoodServicesServices.weKnowYourProblems.PAQsList as unknown as Problem[])
    : [];
  const weKnowTitle = data.sanityTemplateFoodServicesServices.weKnowYourProblems?.title
    ? data.sanityTemplateFoodServicesServices.weKnowYourProblems.title
    : "";

  return (
    <Layout>
      <div className="l-logistics-services">
        <HeroService
          title={data.sanityTemplateFoodServicesServices.hero?.title}
          subtitle={data.sanityTemplateFoodServicesServices.hero?.subtitle}
          resume={data.sanityTemplateFoodServicesServices.hero?._rawText}
          photo={data.sanityTemplateFoodServicesServices.hero?.photo}
        />
        {data.sanityTemplateFoodServicesServices.has_brands && <BrandsLogistics />}
        <Gallery photosList={photosList} />
        <BenefitsAndInfo
          info={data.sanityTemplateFoodServicesServices.benefitsAndInfo?.info}
          benefits={data.sanityTemplateFoodServicesServices.benefitsAndInfo?.benefits}
        />
        {has_video && (
          <Video
            code={data.sanityTemplateFoodServicesServices.video?.code}
            photo={videoThumbnail}
          />
        )}
        {servicesResumenList.length > 0 && <ServicesResume servicesList={servicesResumenList} />}
        <WeKnowYourProblems
          title={weKnowTitle}
          problemsList={problemList}
        />
        <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default FoodServicesServicesTemplate;

export const Head = ({ data }: PageProps<Queries.FoodServicesServicesQuery>) => (
  <>
    <SEO
      title={data.sanityTemplateFoodServicesServices?.seo?.title}
      robots={data.sanityTemplateFoodServicesServices?.seo?.seo_robots}
      description={data.sanityTemplateFoodServicesServices?.seo?.description}
      canonical={`distribucion-comercial/${data.sanityTemplateFoodServicesServices?.seo?.canonical}`}
      imageAbsolutePath={data.sanityTemplateFoodServicesServices?.seo?.thumbnail?.asset?.url}
    />
    <ServiceMarkupSchema
      titleHero={data.sanityTemplateFoodServicesServices?.hero?.title}
      descriptionService={data.sanityTemplateFoodServicesServices?.seo?.description}
      absolutePathImageOG={data.sanityTemplateFoodServicesServices?.seo?.thumbnail?.asset?.url}
      absolutePathService={`
        ${data.sanityDocumentData?.urlSite}${data.sanityTemplateFoodServicesServices?.slug?.current}
      `}
      problemList={data.sanityTemplateFoodServicesServices?.weKnowYourProblems?.PAQsList as Problem[]}
    />
  </>
);

export const query = graphql`
  query FoodServicesServices($id: String) {
    sanityDocumentData {
      urlSite
    }
    sanityTemplateFoodServicesServices(id: { eq: $id }) {
      slug {
        current
      }
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
      hero {
        title
        subtitle
        _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
              url
            }
          }
        }
      }
      has_brands
      gallery {
        image {
          asset {
            _id
            gatsbyImageData
          }
        }
      }
      benefitsAndInfo: benefits {
        info {
          title
          photo {
            alt
            image {
              asset {
                _id
                gatsbyImageData
              }
            }
          }
          text: _rawText
        }
        benefits {
          title
          benefitsList {
            text: _rawBlock
          }
        }
      }
      video {
        code
        photo: image {
          asset {
            _id
            gatsbyImageData
          }
        }
        alt
      }
    }
    sanityPageOurProducts {
      seo {
        thumbnail {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
      hero {
        title
      }
      slug {
        current
      }
    }
    sanityPageApp {
      seo {
        thumbnail {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
    }
    allSanityTemplateFoodServicesServices(filter: { id: { ne: $id } }) {
      nodes {
        seo {
          thumbnail {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        hero {
          title
        }
        slug {
          current
        }
      }
    }
  }
`;
